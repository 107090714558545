import React from 'react'
import debounce from '../helper/debounce'
import chevron from '../../assets/media/right-chevron.svg'

const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = React.useState(false);
    
    React.useEffect(() => {
        if (typeof window === "undefined") { return; };

        if (ref) {
            observer.observe(ref)
        }

      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [ref])

    if (typeof window === "undefined") { return false };

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )
  
    return isIntersecting
}

const CarouselItem = React.forwardRef(({children, className, id, style, active}, ref) => (
    <div id={id} ref={ref} style={style} className={`slide ${className ? className : ''} ${active ? 'active' : ''}`}>
        {children}
    </div>
))

const SimpleCarousel = (props) => {
    
    const [storeRef, setStoreRef] = React.useState(null);
    const isVisible = useOnScreen(storeRef?.current);

    return <SimpleCarouselComponent 
        {...props} 
        setStoreRef={setStoreRef}
        canAutoplay={isVisible}
    />
}

class SimpleCarouselComponent extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            active: 0,
            arrows: false,
            bullets: false,
            w: 0,
            h: 0,
            swipeX: 0,
            count: 0,
            sliderActive: this.props.sliderActive,
            autoplay: 6000
        }
        this.carouselrefs = []
        this.containerref = React.createRef();
    }

    componentDidUpdate() {
        if (this.state.sliderActive !== this.props.sliderActive) {
            this.setState({
                sliderActive: this.props.sliderActive,
            });
        }
        // this.updateComponentDevice();
    }
    
    getCurrentDevice() {
        const layout = document.getElementsByClassName('layout');
        const device = layout[0].getAttribute('device');
        return device;
    }

    updateComponentDevice() {
        const device = this.getCurrentDevice();
        if (device === 'mobile') {
            if (this.state.autoplay !== 6000) {
                this.setState({
                    autoplay: 6000,
                })
            }
        } else {
            if (this.state.autoplay !== 3000) {
                this.setState({
                    autoplay: 7000,
                })
            }
        }
    }

    componentDidMount() {
        const { arrows, bullets } = this.props
        
        const dimensions = this.getCarouselContainerWidth()
        const clock = this.startClock()
        this.updateComponentDevice();

        window.addEventListener('resize', this.updateCarouselDimensions)

        this.setState({
            arrows: arrows,
            bullets: bullets,
            w: dimensions.w,
            h: dimensions.h,
            count: dimensions.count,
            clock: clock
        })

        this.props.setStoreRef(this.containerref)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateCarouselDimensions)
    }

    startClock = () => {
        return setTimeout(() => {
            if (this.props.canAutoplay) {
                this.changeActiveSlide('next')
            } else {
                this.startClock()
            }
        }, this.state.autoplay)
    }

    clearClock = () => {
        const { clock } = this.state
        clearInterval(clock)
    }

    updateCarouselDimensions = debounce(() => {
        const dimensions = this.getCarouselContainerWidth()
        this.setState({
            w: dimensions.w,
            h: dimensions.h,
        })
    }, 100)


    arrows = () => {

        const {count} = this.state;
        
        return (
            <React.Fragment>
                {
                count ? (
                    <React.Fragment>
                        <button 
                        className={`arrow carousel-prev`}
                        onClick={() => this.changeActiveSlide('prev')}
                        tabIndex="1"
                    > <img src={chevron} alt="previous" /> </button>

                    <button 
                        className={`arrow carousel-next`} 
                        onClick={() => this.changeActiveSlide('next')}
                        tabIndex="2"
                    > <img src={chevron} alt="next" /> </button>
                    </React.Fragment>
                ) : null
                }
            </React.Fragment>
           
        )
    }

    bullets = () => {
        const {count} = this.state;
        return (
           <React.Fragment>
               {
                   count ? (
                        <div className="carousel-controls bullets">
                            <ul className="inline">
                            {this.carouselrefs.map((item, i) => {
                                return <li key={`item_${i}`}>
                                            <a
                                                role="button"
                                                className={`${this.state.active === i ? 'active' : ''} bullet`} 
                                                onClick={() => this.changeActiveSlide(i)}
                                            > </a>
                                        </li>
                            })}
                            </ul>
                    </div>
                   ) : null
               }
           </React.Fragment>
        ) 
    }   

    changeActiveSlide = (idx) => {

        const { count, active } = this.state
        
        let nextActive = idx

        if (idx === 'next') {
            nextActive = active+1
            if (nextActive > count) {
                nextActive = 0
            }
        }

        if (idx === 'prev') {
            nextActive = active-1
            if (nextActive < 0) {
                nextActive = count
            }
        }

        // reset the clock
        if(this.state.sliderActive) {
            this.clearClock()
            const clock = this.startClock()
    
            this.setState({
                active: nextActive,
                clock: clock
            })
        }
    }

    handleKeyHandler = (e) => {
        if (e.keyCode === 37) {
            this.changeActiveSlide('prev')
        }
        if (e.keyCode === 39) {
            this.changeActiveSlide('next')
        }
    }

    touchStartHandler = (e) => {
        const { clientX: swipeX } = e.touches[0]
        this.setState({
            swipeX: swipeX
        })
    }

    touchMoveHandler = (e) => {
        const { swipeX } = this.state
        const { clientX: moveX } = e.touches[0]
        let direction = swipeX - moveX

        if (direction > 0) {
            // left
            direction = 'next'
        } else {
            // right
            direction = 'prev'
        }

        this.setState({
            swipeDir: direction,
        })
    }

    touchEndHandler = (e) => {
        const { swipeDir } = this.state
        if (swipeDir) {
            this.changeActiveSlide(swipeDir)
            this.setState({
                swipeDir: false
            })
        }
    }

    getCarouselContainerWidth = () => {
        const container = this.containerref ? this.containerref.current : false

        // make sure all the refs are loaded
        if (container) {
            const {width: w, height: h} = container.getBoundingClientRect()

            const count = this.carouselrefs.length-1

            return {
                w: w,
                h: h,
                count: count
            }
        }
    }

    getContainerStyle = () => {
        const { w, count: slides } = this.state
        const containerWidth = w * slides ? w * slides : w
        return {
            width: `${containerWidth}px`,
        }
    }

    getSlideStyle = () => {
        const { w } = this.state
        return {
            width: `${w}px`,
        }
    }

    render () {
        const { children, device } = this.props
        const { active } = this.state

        const arrows = this.arrows()
        const bullets = this.bullets()
        const styles = {
            container: this.getContainerStyle(),
            slide: this.getSlideStyle()
        }

        return (
            <div 
                className="carousel" 
                tabIndex="0" 
                onKeyDown={this.handleKeyHandler} 
                onTouchStart={this.touchStartHandler} 
                onTouchMove={this.touchMoveHandler}
                onTouchEnd={this.touchEndHandler}
            >
                {arrows}
                <div className="carousel-grid" ref={this.containerref}>
                    <div className="carousel-container" style={styles.container}>
                        {React.Children.map(children, (child, i) =>{

                            // here is where you should create a ref and assign it to the child
                            let ref = null
                            if (this.carouselrefs[i] && this.carouselrefs[i].ref) {
                                ref = this.carouselrefs[i].ref
                            } else {
                                ref = React.createRef()
                                this.carouselrefs[i] = ref
                            }

                            // check if this is the active slide
                            let isActive = false
                            if (i === active) {
                                isActive = true
                            }

                            return React.cloneElement(
                                child, 
                                { 
                                    device: device,
                                    key: `cslide_${i}`,
                                    ref: ref,
                                    style: styles.slide,
                                    active: isActive
                                })
                        })}
                    </div>
                </div>
                {bullets}
            </div>
        )
    }
}

SimpleCarousel.defaultProps = {
    sliderActive: true
}

export {
    CarouselItem,
    SimpleCarousel,
}