import React from 'react';
import returnWithFallback from '../../util/helper/returnWithFallback';
import dateFormat from '../helper/dateFormat';
import ImageFromField from './ImageFromField';
import Link from './Link';

const ExhibitionCard = ({ exhibition, device, viewButton = false }) => {
  /* let imageLink = returnWithFallback({
    fallback: exhibition.relationships.field_featured_image,
    optional: 'image_style_uri',
    list_key: 'featured_image_crop',
    list: true,
  }); */

  return (
    <>
      {exhibition.title ? (
        <>
          {viewButton ? (
            <div className="grid-2 exh-desktop">
              <div className="grid-col">
                <ExhibitionHeadline exhibition={exhibition} />
              </div>
              <div className="grid-col justify-end align-center exh-btn">
                <Link
                  button
                  className={`btn-medium btn-center`}
                  path={exhibition.path.alias}
                  linkkey={`search_btn`}
                  device={device}
                >
                  View Exhibition
                </Link>
              </div>
            </div>
          ) : (
            <div className="t-heading-container">
              <ExhibitionHeadline exhibition={exhibition} />
            </div>
          )}

          <div
            className="exhibition-banner"
            style={{
              width: '100%',
              // maxWidth: '1200px'
            }}
          >
            <Link path={exhibition.path.alias}>
              <ImageFromField
                img={exhibition.relationships.field_featured_image}
                style={{ width: '100%', height: 'auto' }}
                fallback={'./media/logo.png'}
                imageSize="featured_image_crop"
                direct={true}
              />
            </Link>
          </div>

          {viewButton ? (
            <div className="grid-2 exh-mobile">
              <div className="grid-col">
                <ExhibitionHeadline exhibition={exhibition} />
              </div>
              <div className="grid-col justify-end align-center exh-btn">
                <Link
                  button
                  className={`btn-medium btn-center`}
                  path={exhibition.path.alias}
                  linkkey={`search_btn`}
                  device={device}
                >
                  View Exhibition
                </Link>
              </div>
            </div>
          ) : (
            <div className="t-heading-container">
              <ExhibitionHeadline exhibition={exhibition} />
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default ExhibitionCard;

const ExhibitionHeadline = ({ exhibition }) => {
  const locations = exhibition.relationships.field_locations_ref
    ? exhibition.relationships.field_locations_ref
      .map((ele) => ele.title.split(',')[1])
      .join(', ')
    : 'Virtual';

  return (
    <>
      <h3 className="t-large t-subheading t-center-sm exh-title">
        {exhibition.title}
      </h3>
      {exhibition?.field_dates?.end_value &&
        exhibition?.field_dates?.value &&
        locations ? (
        <h4 className="t-medium t-subheading t-center-sm exh-date">
          {dateFormat(exhibition.field_dates.value)} -{' '}
          {dateFormat(exhibition.field_dates.end_value)}, {locations}
        </h4>
      ) : null}
    </>
  );
};
